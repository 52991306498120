import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "styled-components"

const SpanContainer = styled.span`
    margin: 0 0.15rem;
`

const StyledSvg = styled.svg`
    width: "40";
    height: "15";
`

const DogBone = ({fillColor}) => {
    // use react (hook from mui) hook to see user screen
    const checkSmallScreen = useMediaQuery("(max-width: 768px)");

    return(
        // TODO Logic for this would be accessing reviews for each trail? So repeating this 5 times? 
        // or could contain logic within a review component of some kind?
        // going to contain within span for inline 
        <SpanContainer>
            {checkSmallScreen ? (
                <StyledSvg id="dog-bone" width="13" height="20" viewBox="0 0 60 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M52.4988 0.766292C51.6597 1.09882 50.0656 2.24624 48.9567 3.3163L46.9409 5.26216L16.0634 5.64097L14.2941 4.16712C9.91346 0.517807 5.69018 -0.278804 2.79127 1.99653C0.452604 3.83215 -0.00450981 5.45156 1.05924 8.12945C1.7678 9.9139 1.81235 10.8208 1.27163 12.4566C-0.246798 17.0475 3.37959 20.3363 8.83383 19.3155C9.98853 19.0993 12.3827 17.8356 14.1538 16.5079L17.3737 14.0931H31.2348C39.3152 14.0931 45.7892 14.3562 46.7577 14.7235C47.672 15.0706 49.3472 16.1668 50.4805 17.1596C55.2586 21.3461 61.5361 17.3069 59.3274 11.4681C58.8783 10.28 58.8465 9.29394 59.2341 8.57039C60.3857 6.42355 59.9048 3.72862 58.0385 1.86559C56.1655 -0.00352275 55.0188 -0.230691 52.4988 0.766292ZM4.89558 1.71942C3.17514 2.37352 1.23378 4.55933 1.23378 5.84195C1.23378 6.54662 1.65794 7.72751 2.17669 8.46625C2.96825 9.59419 3.00182 10.202 2.38481 12.2575C1.41138 15.5 2.30181 17.5134 5.02435 18.2242C7.84818 18.9617 11.1102 17.9854 13.9616 15.5499L16.3802 13.4841H31.4185C47.0776 13.4841 48.5661 13.6978 50.6874 16.2478C53.0316 19.067 57.0559 18.6724 58.387 15.4932C59.1493 13.6735 59.1468 13.1747 58.368 11.6174C57.582 10.0442 57.5838 9.61127 58.3857 8.00707C59.9811 4.81574 57.986 1.30346 54.5775 1.30346C53.1256 1.30346 51.8171 1.95634 49.8312 3.67137L47.0898 6.03928L35.1566 6.48631C18.609 7.10511 16.9319 6.93031 12.9136 4.16468C11.1139 2.92591 9.32877 1.90883 8.94673 1.90396C8.56469 1.8997 7.70296 1.77789 7.03163 1.63355C6.3603 1.48921 5.39907 1.52758 4.89558 1.71942Z" fill="black"/>
                <path d="M4.89558 1.71942C3.17514 2.37352 1.23378 4.55933 1.23378 5.84195C1.23378 6.54662 1.65794 7.72751 2.17669 8.46625C2.96825 9.59419 3.00182 10.202 2.38481 12.2575C1.41138 15.5 2.30181 17.5134 5.02435 18.2242C7.84818 18.9617 11.1102 17.9854 13.9616 15.5499L16.3802 13.4841H31.4185C47.0776 13.4841 48.5661 13.6978 50.6874 16.2478C53.0316 19.067 57.0559 18.6724 58.387 15.4932C59.1493 13.6735 59.1468 13.1747 58.368 11.6174C57.582 10.0442 57.5838 9.61127 58.3857 8.00707C59.9811 4.81574 57.986 1.30346 54.5775 1.30346C53.1256 1.30346 51.8171 1.95634 49.8312 3.67137L47.0898 6.03928L35.1566 6.48631C18.609 7.10511 16.9319 6.93031 12.9136 4.16468C11.1139 2.92591 9.32877 1.90883 8.94673 1.90396C8.56469 1.8997 7.70296 1.77789 7.03163 1.63355C6.3603 1.48921 5.39907 1.52758 4.89558 1.71942Z" fill={fillColor}/>
                </StyledSvg> 
            ) : (
                <StyledSvg id="dog-bone" width="30" height="20" viewBox="0 0 60 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M52.4988 0.766292C51.6597 1.09882 50.0656 2.24624 48.9567 3.3163L46.9409 5.26216L16.0634 5.64097L14.2941 4.16712C9.91346 0.517807 5.69018 -0.278804 2.79127 1.99653C0.452604 3.83215 -0.00450981 5.45156 1.05924 8.12945C1.7678 9.9139 1.81235 10.8208 1.27163 12.4566C-0.246798 17.0475 3.37959 20.3363 8.83383 19.3155C9.98853 19.0993 12.3827 17.8356 14.1538 16.5079L17.3737 14.0931H31.2348C39.3152 14.0931 45.7892 14.3562 46.7577 14.7235C47.672 15.0706 49.3472 16.1668 50.4805 17.1596C55.2586 21.3461 61.5361 17.3069 59.3274 11.4681C58.8783 10.28 58.8465 9.29394 59.2341 8.57039C60.3857 6.42355 59.9048 3.72862 58.0385 1.86559C56.1655 -0.00352275 55.0188 -0.230691 52.4988 0.766292ZM4.89558 1.71942C3.17514 2.37352 1.23378 4.55933 1.23378 5.84195C1.23378 6.54662 1.65794 7.72751 2.17669 8.46625C2.96825 9.59419 3.00182 10.202 2.38481 12.2575C1.41138 15.5 2.30181 17.5134 5.02435 18.2242C7.84818 18.9617 11.1102 17.9854 13.9616 15.5499L16.3802 13.4841H31.4185C47.0776 13.4841 48.5661 13.6978 50.6874 16.2478C53.0316 19.067 57.0559 18.6724 58.387 15.4932C59.1493 13.6735 59.1468 13.1747 58.368 11.6174C57.582 10.0442 57.5838 9.61127 58.3857 8.00707C59.9811 4.81574 57.986 1.30346 54.5775 1.30346C53.1256 1.30346 51.8171 1.95634 49.8312 3.67137L47.0898 6.03928L35.1566 6.48631C18.609 7.10511 16.9319 6.93031 12.9136 4.16468C11.1139 2.92591 9.32877 1.90883 8.94673 1.90396C8.56469 1.8997 7.70296 1.77789 7.03163 1.63355C6.3603 1.48921 5.39907 1.52758 4.89558 1.71942Z" fill="black"/>
                <path d="M4.89558 1.71942C3.17514 2.37352 1.23378 4.55933 1.23378 5.84195C1.23378 6.54662 1.65794 7.72751 2.17669 8.46625C2.96825 9.59419 3.00182 10.202 2.38481 12.2575C1.41138 15.5 2.30181 17.5134 5.02435 18.2242C7.84818 18.9617 11.1102 17.9854 13.9616 15.5499L16.3802 13.4841H31.4185C47.0776 13.4841 48.5661 13.6978 50.6874 16.2478C53.0316 19.067 57.0559 18.6724 58.387 15.4932C59.1493 13.6735 59.1468 13.1747 58.368 11.6174C57.582 10.0442 57.5838 9.61127 58.3857 8.00707C59.9811 4.81574 57.986 1.30346 54.5775 1.30346C53.1256 1.30346 51.8171 1.95634 49.8312 3.67137L47.0898 6.03928L35.1566 6.48631C18.609 7.10511 16.9319 6.93031 12.9136 4.16468C11.1139 2.92591 9.32877 1.90883 8.94673 1.90396C8.56469 1.8997 7.70296 1.77789 7.03163 1.63355C6.3603 1.48921 5.39907 1.52758 4.89558 1.71942Z" fill={fillColor}/>
                </StyledSvg>    
            )}
        </SpanContainer>
        
    )
}

export default DogBone